import React from 'react';

import MainSvg from './MainSvg';
import StructureSvg from './StructureSvg';

const SmartFM = ({ close }) => {
  const [structure, showStructure] = React.useState(false);
  return (
    <>
      {structure ? (
        <StructureSvg close={close} />
      ) : (
        <MainSvg close={close} showStructure={() => showStructure(true)} />
      )}
    </>
  );
};
export default SmartFM;
