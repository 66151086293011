import React, { useState } from 'react';
import '../node_modules/animate.css/animate.min.css';
import './App.css';

import Image from './components/Image';
import Tile from './components/Tile';
import Modal from './components/Modal';

import DigitalCounter from './components/DigitalCounter';
import Calculator from './components/Calculator';
import DigitalOrganization from './components/DigitalOrganization';
import DataWarehouse from './components/DataWarehouse';
import SmartFm from './components/Gid/SmartFm';
import OptimaHospital from './components/Gid/OptimaHospital';
import GuardFm from './components/Gid/GuardFm';
import Portal from './components/Gid/Portal';
import SapErp from './components/Gi/SapErp';
import SapHr from './components/Gi/SapHr';
import SapCrm from './components/Gi/SapCrm';
import I360 from './components/Gi/I360';
import Qv from './components/Gi/Qv';

const App = () => {
  const [showModal, setShowModal] = useState({
    isOpen: false,
    type: '',
    title: '',
    backgroundColor: '',
  });
  const show = (type, title, backgroundColor = '#ffffff') => {
    setShowModal({
      isOpen: true,
      type,
      title,
      backgroundColor,
    });
  };

  const close = () => {
    setShowModal({
      isOpen: false,
      type: '',
      title: '',
      backgroundColor: '',
    });
  };

  const renderContent = () => {
    if (!showModal.isOpen) {
      return null;
    }
    switch (showModal.type) {
      case 'coreSystems':
        if (showModal.title === 'Digital Counter') {
          return <DigitalCounter close={close} />;
        }
        if (showModal.title === 'Calculator') {
          return <Calculator close={close} />;
        }
        if (showModal.title === 'Digital Organization') {
          return <DigitalOrganization close={close} />;
        }

        if (showModal.title === 'Data Warehouse') {
          return <DataWarehouse close={close} />;
        }
        close();
        return null;

      case 'Tile':
        return (
          <Tile
            title={showModal.title}
            backgroundColor={showModal.backgroundColor}
            close={close}
          />
        );
      case 'TileWithContentForGi':
        if (showModal.title === 'SAP ERP') {
          return <SapErp close={close} />;
        }
        if (showModal.title === 'SAP HR') {
          return <SapHr close={close} />;
        }
        if (showModal.title === 'SAP CRM') {
          return <SapCrm close={close} />;
        }
        if (showModal.title === 'i360') {
          return <I360 close={close} />;
        }

        if (showModal.title === 'Qlik View') {
          return <Qv close={close} />;
        }

        close();
        return null;
      case 'TileWithContentForGid':
        if (showModal.title === 'Smart FM') {
          return <SmartFm close={close} />;
        }
        if (showModal.title === 'Guard FM') {
          return <GuardFm close={close} />;
        }

        if (showModal.title === 'Optima FM') {
          return <OptimaHospital close={close} />;
        }
        if (showModal.title === 'Portal') {
          return <Portal close={close} />;
        }
        close();
        return null;

      default:
        close();
        return null;
    }
  };
  return (
    <div className='App'>
      <Image showModal={show} />
      {showModal.isOpen && <Modal>{renderContent()}</Modal>}
    </div>
  );
};

export default App;
