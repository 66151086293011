import React from 'react';

const Idea = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      width='50%'
      height='50%'
      enableBackground='new 0 0 480 480'
      viewBox='0 0 480 480'
    >
      <path
        fill='#fff'
        d='M106.904 376.368A55.54 55.54 0 00128 336c0-52.896-30.456-64-56-64s-56 11.104-56 64a55.537 55.537 0 0021.096 40.392C15.89 379.005-.031 397.033 0 418.4V480h16v-61.6c0-14.58 11.82-26.4 26.4-26.4h17.76l-11.592 29.024a8.001 8.001 0 001.776 8.632l16 16a8 8 0 0011.312 0l16-16a8.001 8.001 0 001.776-8.632L83.816 392H101.6c14.58 0 26.4 11.82 26.4 26.4V480h16v-61.6c.043-21.376-15.881-39.418-37.096-42.032zm-74.488-48.832C34.584 300.728 47.2 288 72 288c22.704 0 35.2 10.736 38.776 33.136l-26.656-16a8 8 0 00-11.704 4.328c-4.048 12.144-25.896 16.736-40 18.072zM72 428.664l-6.616-6.616L72 405.536l6.616 16.536L72 428.664zM72 376a50.402 50.402 0 01-38.888-32.416c13.696-1.128 38.4-5.28 49.92-20.432l28.6 17.16C108.416 359.896 84.28 376 72 376z'
      />
      <path fill='#fff' d='M32 448H48V480H32z' />
      <path fill='#fff' d='M96 448H112V480H96z' />
      <path
        fill='#fff'
        d='M274.904 376.368A55.54 55.54 0 00296 336c0-52.896-30.456-64-56-64s-56 11.104-56 64a55.537 55.537 0 0021.096 40.392C183.89 379.005 167.969 397.033 168 418.4V480h16v-61.6c0-14.58 11.82-26.4 26.4-26.4h17.76l-11.608 29.024a8.001 8.001 0 001.776 8.632l16 16a8 8 0 0011.312 0l16-16a8.001 8.001 0 001.776-8.632L251.816 392H269.6c14.58 0 26.4 11.82 26.4 26.4V480h16v-61.6c.043-21.376-15.881-39.418-37.096-42.032zm-74.488-48.832C202.584 300.728 215.2 288 240 288c22.704 0 35.2 10.736 38.776 33.136l-26.656-16a8 8 0 00-11.704 4.328c-4.048 12.144-25.896 16.736-40 18.072zM240 428.664l-6.616-6.616L240 405.536l6.616 16.536-6.616 6.592zM240 376a50.402 50.402 0 01-38.888-32.416c13.696-1.128 38.4-5.28 49.92-20.432l28.6 17.16C276.416 359.896 252.28 376 240 376z'
      />
      <path fill='#fff' d='M200 448H216V480H200z' />
      <path fill='#fff' d='M264 448H280V480H264z' />
      <path
        fill='#fff'
        d='M461.112 383.128a42.288 42.288 0 00-18.208-6.736A55.537 55.537 0 00464 336c0-52.896-30.456-64-56-64s-56 11.104-56 64a55.537 55.537 0 0021.096 40.392C351.89 379.005 335.969 397.033 336 418.4V480h16v-61.6c0-14.58 11.82-26.4 26.4-26.4h17.76l-11.608 29.024a8.001 8.001 0 001.776 8.632l16 16a8 8 0 0011.312 0l16-16a8.001 8.001 0 001.776-8.632L419.816 392H437.6c14.58 0 26.4 11.82 26.4 26.4V480h16v-61.6a42.345 42.345 0 00-18.888-35.272zm-92.696-55.592C370.584 300.728 383.2 288 408 288c22.704 0 35.2 10.736 38.776 33.136l-26.656-16a8 8 0 00-11.704 4.328c-4.048 12.144-25.896 16.736-40 18.072zM408 428.688l-6.616-6.616L408 405.536l6.616 16.536-6.616 6.616zM408 376a50.402 50.402 0 01-38.888-32.416c13.696-1.128 38.4-5.28 49.92-20.432l28.6 17.16C444.416 359.896 420.28 376 408 376z'
      />
      <path fill='#fff' d='M368 448H384V480H368z' />
      <path fill='#fff' d='M432 448H448V480H432z' />
      <path
        fill='#fff'
        d='M456 80H328c-13.255 0-24 10.745-24 24v8h-64V48c0-13.255-10.745-24-24-24H24C10.745 24 0 34.745 0 48v96c0 13.255 10.745 24 24 24h17.6l24.272 29.12a8 8 0 0012.288 0L102.4 168H136v32c0 13.255 10.745 24 24 24h49.6l24.272 29.12a8 8 0 0012.288 0L270.4 224H320c13.255 0 24-10.745 24-24v-8h41.6l24.272 29.12a8 8 0 0012.288 0L446.4 192h9.6c13.255 0 24-10.745 24-24v-64c0-13.255-10.745-24-24-24zM98.664 152a8 8 0 00-6.144 2.88L72 179.504 51.48 154.88a8 8 0 00-6.144-2.88H24a8 8 0 01-8-8V48a8 8 0 018-8h192a8 8 0 018 8v96a8 8 0 01-8 8H98.664zM328 200a8 8 0 01-8 8h-53.336a8 8 0 00-6.144 2.88L240 235.504l-20.52-24.624a8 8 0 00-6.144-2.88H160a8 8 0 01-8-8v-32h64c13.255 0 24-10.745 24-24v-16h80a8 8 0 018 8v64zm136-32a8 8 0 01-8 8h-13.336a8 8 0 00-6.144 2.88L416 203.504l-20.52-24.624a8 8 0 00-6.144-2.88H344v-40c0-13.255-10.745-24-24-24v-8a8 8 0 018-8h128a8 8 0 018 8v64z'
      />
      <path fill='#fff' d='M32 56H48V72H32z' />
      <path fill='#fff' d='M64 56H80V72H64z' />
      <path fill='#fff' d='M96 56H112V72H96z' />
      <path fill='#fff' d='M256 144H272V160H256z' />
      <path fill='#fff' d='M288 144H304V160H288z' />
      <path fill='#fff' d='M368 112H384V128H368z' />
      <path fill='#fff' d='M400 112H416V128H400z' />
      <path fill='#fff' d='M432 112H448V128H432z' />
      <path
        fill='#fff'
        d='M280 0v16c26.497.031 47.969 21.503 48 48h16c-.04-35.33-28.67-63.96-64-64zM112 208H96c.04 35.33 28.67 63.96 64 64v-16c-26.497-.031-47.969-21.503-48-48z'
      />
    </svg>
  );
};

export default Idea;
