import React from 'react';
import './Tile.css';
import Materials from './icons/Materials';
import Idea from './icons/Idea';
import Equipment from './icons/Equipment';
import Truck from './icons/Truck';
import Money from './icons/Money';

const Tile = ({ title, backgroundColor, close }) => {
  const icon = () => {
    switch (title) {
      case 'Materiały':
        return (
          <div className='svg-container'>
            <Materials />
          </div>
        );
      case 'Robocizna':
        return (
          <div className='svg-container'>
            <Idea />
          </div>
        );
      case 'Sprzęt':
        return (
          <div className='svg-container'>
            <Equipment />
          </div>
        );
      case 'Transport':
        return (
          <div className='svg-container'>
            <Truck />
          </div>
        );
      case 'Zaopatrzenie':
        return (
          <div className='svg-container'>
            <Money />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <button onClick={close} className='tile' style={{ backgroundColor }}>
      {icon(title)}
      <p>{title}</p>
    </button>
  );
};

export default Tile;
