import React from 'react';
import './Modal.css';

const Modal = ({ children }) => (
  <div className='modal'>
    <div className='modal-content animate__animated animate__zoomIn'>
      {children}
    </div>
  </div>
);

export default Modal;
