import React from 'react';

const Money = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='50%'
    height='50%'
    viewBox='0 0 511.969 511.969'
  >
    <g>
      <g>
        <path
          fill='#fff'
          d='M426.523 283.917l-76.444-119.836c10.831-5.692 18.24-17.05 18.24-30.112 0-14.297-8.875-26.553-21.402-31.57l23.158-80.964a10.001 10.001 0 00-2.543-9.821c-15.405-15.404-40.469-15.404-55.874 0-3.685 3.685-8.584 5.714-13.795 5.714s-10.11-2.029-13.818-5.738l-.049-.049c-15.35-15.352-40.371-15.395-55.783-.091l-.287.286c-7.607 7.553-19.962 7.531-27.541-.049l-.073-.073c-15.405-15.404-40.469-15.404-55.874 0a10.004 10.004 0 00-2.542 9.828l23.208 80.938c-12.554 5.004-21.453 17.273-21.453 31.59 0 13.062 7.408 24.42 18.24 30.112L85.446 283.917c-29.574 46.36-31.448 102.826-5.013 151.045s75.047 77.007 130.038 77.007h91.026c54.99 0 103.602-28.788 130.038-77.007 26.435-48.219 24.562-104.685-5.012-151.045zM186.169 25.756l.073.073c15.352 15.351 40.372 15.393 55.783.091l.287-.286c7.606-7.553 19.961-7.531 27.564.072l.049.049c15.405 15.404 40.469 15.404 55.874 0 6.268-6.268 15.775-7.371 23.183-3.309L326.81 99.969H185.22l-22.23-77.524c7.408-4.061 16.912-2.956 23.179 3.311zm227.829 399.592c-22.87 41.716-64.926 66.622-112.5 66.622h-91.026c-47.574 0-89.63-24.905-112.5-66.622-22.87-41.716-21.249-90.566 4.336-130.675l80.825-126.704h27.852c5.523 0 10-4.477 10-10s-4.477-10-10-10h-33.334c-7.72 0-14-6.28-14-14s6.28-14 14-14H334.318c7.72 0 14 6.28 14 14s-6.28 14-14 14h-33.333c-5.523 0-10 4.477-10 10s4.477 10 10 10h27.851l80.826 126.704c25.585 40.108 27.206 88.958 4.336 130.675z'
        />
        <path
          fill='#fff'
          d='M273.615 326.579c-2.509-.887-5.07-1.81-7.63-2.769v-45.322c6.78 1.995 11.308 5.23 11.5 5.37 4.399 3.295 10.64 2.421 13.964-1.966 3.335-4.402 2.47-10.674-1.932-14.01-.587-.445-9.885-7.347-23.532-9.96v-9.952c0-5.523-4.477-10-10-10s-10 4.477-10 10v9.88a51.689 51.689 0 00-5.151 1.259c-14.074 4.229-24.594 16.265-27.455 31.411-2.611 13.83 1.82 27.169 11.564 34.811 5.288 4.147 11.895 7.934 21.042 11.956v57.996c-7.875-.498-13.143-2.29-21.925-8.035-4.621-3.025-10.82-1.728-13.843 2.894-3.024 4.622-1.728 10.819 2.894 13.843 12.806 8.377 21.477 10.75 32.874 11.311v10.341c0 5.523 4.477 10 10 10s10-4.477 10-10v-11.38c21.414-4.69 33.938-22.559 36.748-39.275 3.673-21.854-7.756-40.853-29.118-48.403zm-36.329-16.986c-3.844-3.015-5.474-8.902-4.254-15.363 1.122-5.942 5.12-13.107 12.953-15.758v36.623c-3.288-1.753-6.255-3.585-8.699-5.502zm45.723 62.072c-1.44 8.565-7.221 17.649-17.025 21.663v-48.239c.326.116.635.229.965.346 17.929 6.337 16.826 21.679 16.06 26.23z'
        />
        <path
          fill='#fff'
          d='M255.985 167.969c2.63 0 5.21-1.07 7.07-2.93s2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.44-2.93-7.07-2.93-5.21 1.07-7.07 2.93-2.93 4.44-2.93 7.07 1.07 5.21 2.93 7.07 4.439 2.93 7.07 2.93z'
        />
      </g>
    </g>
  </svg>
);

export default Money;
