import React from 'react';

const Equipment = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50%'
      height='50%'
      viewBox='0 0 512 512'
    >
      <path
        fill='#fff'
        d='M64 400H40a8 8 0 000 16h24a8 8 0 000-16zM96 400h-8a8 8 0 000 16h8a8 8 0 000-16z'
      />
      <path
        fill='#fff'
        d='M472 168h-16v-64a32.042 32.042 0 00-32-32H88a32.036 32.036 0 00-32 32v144H40a32.036 32.036 0 00-32 32v128a32.036 32.036 0 0032 32h80a32.042 32.042 0 0032-32v-24h69.75l-4 16H192a8 8 0 00-8 8v24a8 8 0 008 8h280a32.042 32.042 0 0032-32V200a32.042 32.042 0 00-32-32zM136 408a16.021 16.021 0 01-16 16H40a16.021 16.021 0 01-16-16V280a16.021 16.021 0 0116-16h10.23l6.18 18.53A8 8 0 0064 288h32a8 8 0 007.59-5.47l6.18-18.53H120a16.021 16.021 0 0116 16zM67.1 264h25.8l-2.67 8H69.77zM152 368v-24h144v24zm144 16v16h-1.75l-4-16zm-18.25 16h-43.5l4-16h35.5zM200 424v-8h97.01a31.971 31.971 0 003.3 8zm96-224v128H152v-48a32.042 32.042 0 00-32-32H72V104a16.021 16.021 0 0116-16h336a16.021 16.021 0 0116 16v64H328a32.042 32.042 0 00-32 32zm192 208a16.021 16.021 0 01-16 16H328a16.021 16.021 0 01-16-16V200a16.021 16.021 0 0116-16h144a16.021 16.021 0 0116 16z'
      />
      <path
        fill='#fff'
        d='M472 192H328a8 8 0 00-8 8v184a8 8 0 008 8h144a8 8 0 008-8V200a8 8 0 00-8-8zm-8 184H336V208h128z'
      />
      <circle fill='#fff' cx='392' cy='408' r='8' />
    </svg>
  );
};

export default Equipment;
