import React from 'react';

const Materials = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50%'
      height='50%'
      viewBox='0 -57 512 512'
    >
      <path
        fill='#fff'
        d='M506.04 180.492c-7.774-12.554-21.524-20.047-36.782-20.047h-38.086V90.582c0-16.863-14.227-30.578-31.711-30.578H213.02c-.274 0-.458-.078-.532-.125L179.121 13.21C173.203 4.937 163.445 0 153.016 0H31.71C14.223 0 0 13.719 0 30.578v335.149c0 17.257 14.57 31.3 32.484 31.3h377.364c5.879 0 10.957-3.394 13.414-8.32l.011.004 84.563-169.84c6.133-12.308 5.457-26.656-1.797-38.379zM31.71 30h121.306c.898 0 1.507.395 1.699.664l33.418 46.734c5.64 7.891 14.945 12.606 24.887 12.606h186.44c1.048 0 1.58.492 1.712.668v69.773H129.699c-16.836 0-32.148 9.489-39.011 24.176L30 314.492V30.668c.133-.176.664-.668 1.71-.668zm449.27 175.5L400.56 367.023H38.566l79.301-169.699c1.922-4.113 6.68-6.879 11.832-6.879h339.559c4.793 0 9.008 2.184 11.273 5.84 1.274 2.059 2.383 5.328.45 9.215zm0 0'
      />
    </svg>
  );
};

export default Materials;
